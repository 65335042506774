import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import behavior from './modules/behavior'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    accessToken: '',
    user: {},
    // 输入记录
    record: {},
    // 配置信息
    brand: {},
    // 申请输入内容
    apply: {},
  },
  mutations: {
    SET_RECORD_ITEM(state, item) {
      state.record[item.key] = item.value
    },
    SET_APPLY_ITEM(state, item) {
      state.apply[item.key] = item.value
    },
    SET_ACCESS_TOKEN(state, val) {
      state.accessToken = val
    },
    SET_USER(state, val) {
      state.user = val
    },
    SET_BRAND(state, val) {
      state.brand = val
    }
  },
  actions: {
  },
  modules: {
    behavior,
  },
  plugins: [createPersistedState({
    storage: window.localStorage,
    reducer(val) {
      return {
        accessToken: val.accessToken,
        user: val.user,
        brand: val.brand,
        record: val.record,
      }
    }
  }), createPersistedState({
    storage: window.sessionStorage,
    reducer(val) {
      return {
        apply: val.apply,
        behavior: val.behavior,
      }
    }
  })]
})
