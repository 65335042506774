import moment from 'moment'

export function formatDate(val, f) {
  if (!val) return ""
  return moment(val).format(f)
}

// 货币显示格式
export function formatMoney(num, f) {
  f = f || ','
  num = (num || 0).toString()
  let result = '';
  let subNum = num.substring(num.indexOf('.'), num.length)
  let preNum = num.substring(0, num.indexOf('.'))
  while (preNum.length > 3) {
    result = f + preNum.slice(-3) + result
    preNum = preNum.slice(0, preNum.length - 3)
  }
  if (preNum) { result = preNum + result }
  return result + subNum
}

// 银行卡格式化
export function formatBankCardNo(val) {
  return formatter(val)
}

let formatter = (value, gap) => {
  gap = gap || 4
  if (!Number.isInteger(gap)) {
    return ''
  }
  if (value === undefined) {
    return ''
  }
  return [...value].reduce((previousValue, currentValue, currentIndex) => {
    if ((currentIndex + 1) % gap === 0) {
      previousValue += `${currentValue} `
    } else {
      previousValue += currentValue
    }
    return previousValue
  }, '').trim()
}