export const oneImage = '/order-api/apply/oneImage'; // 上传图片
export const brand = '/order-api/system/brand'; // 获取公司信息
export const getUserInfo = '/order-api/user/getUserInfo'; // 获取用户信息
export const getUserInfoDetail = '/order-api/user/getUserInfoDetail'; // 获取用户信息详情
export const userBankCard = '/order-api/user/bank/card'; // 获取用户银行卡信息
export const apply = '/order-api/user/apply/'; // 申请信息（拼接）
export const session = '/order-api/user/login'; // 登录
export const validationCode = '/order-api/app/getValidateCode'; // 获取验证码
export const password = '/order-api/modify/password'; // 修改密码

export const modifyPin = '/order-api/user/modify/pin';//修改pin码
export const registeredUser = '/order-api/user/registeredUser'; // 注册
export const contractList = '/order-api/contract/list'; // 合同列表
export const contractDetail = '/order-api/contract/detail'; // 合同详情
export const repayList = '/order-api/contract/repayList'; // 还款单

// export const applyPre = '/order-api/apply/pre'; // 预申请 1
// export const realnameAuth = '/order-api/apply/realnameAuth'; // 身份信息认证 2
// export const jobAuth = '/order-api/apply/jobAuth'; // 工作认证 3
// export const reationshipAuth = '/order-api/apply/reationshipAuth'; // 联系人认证 4
// export const commit = '/order-api/apply/commit'; // 身份认证 6
// export const sign = '/order-api/contract/sign'; // 签约 7

export const step = '/order-api/apply/step'; // 申请流程

export const product = '/order-api/product/v1/getProduct'; // 获取产品
export const scheduleCalc = '/order-api/contract/scheduleCalc'; // 获取试算
export const applyByCancel = '/order-api/apply/applyByCancel'; // 取消申请
export const getRecommendUrl = '/order-api/recommend/getRecommendUrl'; // 获取邀请链接
export const myRecommend = '/order-api/recommend/myRecommend'; // 我的推广
export const myCoupon = '/order-api/coupon/myCoupon'; // 我的优惠卷
export const useMyCoupon = '/order-api/coupon/useMyCoupon'; // 使用优惠卷
export const behavior = '/order-api/app/behavior'; // 用户行为
export const continuedLoan = '/order-api/apply/continuedLoan'; // 是否续贷
export const checkPhoneHasLoan = '/order-api/contract/checkPhoneHasLoan'; // 账号是否是续贷
export const bankAccount = '/order-api/pay/verify/bankAccount'; // 校验银行账户
export const getCustomer = '/order-api/customer/getCustomer'; // 客户信息
export const updInfo = '/order-api/customer/updateBankInfo'; // 更新客户信息
export const kaptcha = '/order-api/apply/validation/kaptcha'; // 验证手机号验证码
export const getPayPlatAddress = '/order-api/pay/getPayLink'; // 获取还款地址
export const getRepayBankAccount = '/order-api/pay/getRepayBankAccount'; // 获取对公账号

// 字典
export const dictionary = '/order-api/dictionary/'; // 字典
export const EDUCATION = '/order-api/dictionary/EDUCATION'; // 学历
export const MARITAL_STATUS = '/order-api/dictionary/MARITAL_STATUS'; // 婚姻状况
export const DISTRICT = '/order-api/dictionary/DISTRICT'; // 地址
export const MONTHLY_INCOME = '/order-api/dictionary/MONTHLY_INCOME'; // 平均月收入
export const RELATIONSHIP = '/order-api/dictionary/RELATIONSHIP'; // 亲属联系人
export const OTHER_RELATIONSHIP = '/order-api/dictionary/OTHER_RELATIONSHIP'; // 其他联系人
export const BANK = '/order-api/dictionary/BANK'; // 获取银行
export const RELIGION = '/order-api/dictionary/RELIGION'; // 宗教
export const SALARY_TYPE = '/order-api/dictionary/SALARY_TYPE'; // 工资类型
