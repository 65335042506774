<template>
  <div class="layouts">
    <div class="content">
      <slot />
    </div>
    <v-footer />
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import { brand } from '@/utils/api'
import vFooter from './footer'
export default {
  components: {
    vFooter
  },
  data() {
    return {

    }
  },
  created() {
    this.init()
  },
  methods: {
    ...mapMutations(["SET_BRAND"]),
    init() {
      this.getBrand()
    },
    async getBrand() {
      await this.$axios({
        method: "get",
        url: brand,
      })
        .then(async (e) => {
          if (e.status.code === "000") {
            this.SET_BRAND(e.body);
            await this.getUser();
          }
        })
        .catch(() => {})
    }
  }
}
</script>

<style scoped lang="scss">
.layouts {
  .content {
    display: block;
    min-height: 100vh;
  }
}
</style>