<template>
  <Footer class="footer">
    <div class="item" v-if="brand.serviceInfo && brand.serviceInfo.ccphone">
      {{ $t('telephone') }}:
      <a :href="'tel:' + brand.serviceInfo.ccphone">{{ brand.serviceInfo.ccphone }}</a>
    </div>
    <div class="item" v-if="brand.serviceInfo && brand.serviceInfo.email">
      Email:
      {{ brand.serviceInfo.email }}
    </div>
    <!--
    <div class="item" v-if="brand.serviceInfo && brand.serviceInfo.zalo">
      Zalo:
      {{ brand.serviceInfo.zalo }}
    </div>
    <div class="item" v-if="brand.serviceInfo && brand.serviceInfo.fb">
      <a :href="brand.serviceInfo.fb" target="_blank">
        <svg aria-hidden="true" class="icon">
          <use xlink:href="#iconfacebook" />
        </svg>
        Facebook
      </a>
    </div> -->
  </Footer>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(['brand'])
  },
}
</script>

<style scoped lang="scss">
.footer {
  background: #212121;
  color: #fff;
  padding: 10px;
  .item {
    padding: 5px 0;
    .icon {
      vertical-align: middle;
      font-size: 26px;
    }
  }
  a {
    text-decoration: none;
    outline: none;
    color: white;
  }
}
</style>